import React, { useState } from 'react';
import BarcodeScannerComponent from './BarcodeScanner';

// export const backendUrl = "http://localhost:8080";
export const backendUrl = "https://skaap-2-0-server.onrender.com";
function Scan() {
  const [result, setResult] = useState('nothing');

  return (
    <div className='flex items-center bg-black justify-center flex-col min-h-screen'>
      <h1 className='flex items-center justify-center p-2'><b className='text-[#E33054]'>SKAAP</b> <b className='text-white'>IT!</b></h1>
      <BarcodeScannerComponent />
    </div>
  );
}

export default Scan;
