import { BrowserRouter, Routes, Route } from "react-router-dom";
import Scan from "./Home";
import Home from "./RootPage";
import Cart from "./Cart";
import Bill from "./Bill";

export default function Routers() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/Scan" element={<Scan />} />
				<Route path="/cart" element={<Cart />} />
                <Route path="/bill/:id" element={<Bill />} />
			</Routes>
		</BrowserRouter>
	);
}
