import React, { useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { backendUrl } from "./App";
import axios from "axios";

export default function Cart() {
	const [cart, setCart] = useState([]);
	const [discountCode, setDiscountCode] = useState("");
	const [isDiscountApplied, setIsDiscountApplied] = useState(false);
	const [showQR, setShowQR] = useState(false);
	const [qrValue, setQrValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const storedCart = localStorage.getItem("cart");
		if (storedCart) {
			setCart(JSON.parse(storedCart));
		}
	}, []);

	// Calculate the total price
	const total = cart.reduce(
		(acc, item) => acc + item.price * (item.quantity || 1),
		0,
	);
	const finalTotal = isDiscountApplied ? 0 : total;

	const handleApplyDiscount = () => {
		// You can customize this condition for any discount code you want.
		if (discountCode.trim().toUpperCase() === "FREE100") {
			setIsDiscountApplied(true);
		} else {
			setIsDiscountApplied(false);
			alert("Invalid discount code");
		}
	};

	const handleBuy = async () => {
        if (cart.length === 0) {
          alert("Your cart is empty.");
          return;
        }

        if(finalTotal > 0) {
            alert("Please apply discount code to proceed.");
            return;
        }

        setIsLoading(true);
      
        const orderData = {
          products: cart.map(item => item._id),
          discountCode: isDiscountApplied ? "FREE100" : null,
          total: finalTotal
        };
      
        try {
          const response = await axios.post(`${backendUrl}/orders`, orderData);
          if (response.status === 201) {
            const createdOrder = response.data;
            // Use the order's ID to generate a URL for the bill
            const billUrl = `https://skaap.ca/bill/${createdOrder._id}`;
            
            // Set the QR code to the bill URL
            setQrValue(billUrl);
            setShowQR(true);
          }
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.error('Network error:', error);
          alert('Failed to create order. Please try again.');
        }
      };
      

	const removeFromCart = (index) => {
		const newCart = [...cart];
		newCart.splice(index, 1);
		setCart(newCart);
		localStorage.setItem("cart", JSON.stringify(newCart));
	};

	return (
		<div className="min-h-screen bg-gray-50 p-6 flex flex-col">
			<h1 className="text-3xl font-bold mb-6">Your Cart</h1>

			{cart.length > 0 ? (
				<>
					<ul className="bg-white rounded-lg shadow-md divide-y divide-gray-200">
						{cart.map((item, index) => (
							<li
								key={index}
								className="flex p-4 space-x-4 items-center justify-between"
							>
								<div className="flex space-x-4 items-start">
									<img
										src={item.image}
										alt={item.name}
										className="w-16 h-16 rounded object-cover"
									/>
									<div>
										<h2 className="text-lg font-semibold text-gray-800">
											{item.name}
										</h2>
										<p className="text-sm text-gray-600">
											Price: ${item.price}
										</p>
										<p className="text-sm text-gray-600">
											Quantity: {item.quantity}
										</p>
									</div>
								</div>

								<button
									onClick={() => removeFromCart(index)}
									className="text-white hover:text-gray-100 font-semibold bg-[#E33054] p-2 rounded-md"
								>
									Remove
								</button>
							</li>
						))}
					</ul>

					{/* Discount and Total Section */}
					<div className="mt-6 bg-white p-6 rounded-lg shadow-md flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-end sm:justify-between">
						<div className="space-y-2">
							<label
								htmlFor="discount"
								className="block text-gray-700 font-medium"
							>
								Discount Code:
							</label>
							<input
								id="discount"
								type="text"
								placeholder="Enter discount code"
								value={discountCode}
								onChange={(e) => setDiscountCode(e.target.value)}
								className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#E33054]"
							/>
							<button
								onClick={handleApplyDiscount}
								className="inline-block mt-2 bg-[#E33054] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#d02b4a]"
							>
								Apply Discount
							</button>
						</div>

						<div className="text-right">
							<p className="text-xl font-bold text-gray-800">
								Total: ${finalTotal.toFixed(2)}
							</p>
							<button
								onClick={handleBuy}
								className="mt-4 bg-[#E33054] text-white px-4 py-2 rounded-md font-semibold hover:bg-[#d02b4a]"
							>
								Buy
							</button>
						</div>
					</div>

					{showQR && (
						<div className="mt-6 flex flex-col items-center">
							<h2 className="text-lg font-bold mb-4">
								Scan this QR to view your bill
							</h2>
							<div className="bg-white p-4 shadow-md rounded-md">
								<QRCodeCanvas value={qrValue} size={200} />
                                <div className="flex items-center justify-center">
                                <a href={qrValue} target="_blank" rel="noreferrer" className="text-[#E33054] text-center underline">Open Bill</a>
                                </div>
							</div>
						</div>
					)}
				</>
			) : (
				<p className="text-gray-600">Your cart is empty.</p>
			)}
		</div>
	);
}
