import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { backendUrl } from './App';

export default function Bill() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchOrder() {
      try {
        const response = await axios.get(`${backendUrl}/orders/${id}`);
        setOrder(response.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    fetchOrder();
  }, [id]);

  if (loading) return <div>Loading...</div>;
  if (!order) return <div>Order not found</div>;

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <h1 className="text-3xl font-bold mb-6">Your Bill</h1>
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-xl font-semibold mb-4">Order ID: {order._id}</h2>
        <ul className="divide-y divide-gray-200">
          {order.products.map((product, index) => (
            <li key={index} className="flex p-4 space-x-4 items-start justify-between">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">{product.name}</h3>
                <p className="text-sm text-gray-600">Price: ${product.price}</p>
                {/* If quantity is stored in the order, display it here */}
              </div>
            </li>
          ))}
        </ul>
        <div className="mt-4 text-right text-xl font-bold">
          Total: ${order.total.toFixed(2)}
        </div>
        {order.discountCode && (
          <div className="mt-2 text-right text-gray-600">
            Discount Applied: {order.discountCode.code}
          </div>
        )}
      </div>
    </div>
  );
}
