import React, { useState } from 'react';
import BarcodeScannerComponent from './BarcodeScanner';
import Routers from './Routers';

// export const backendUrl = "http://localhost:8080";
export const backendUrl = "https://skaap-2-0-server.onrender.com";
function App() {
  const [result, setResult] = useState('nothing');

  return (
  <Routers />
  );
}

export default App;
