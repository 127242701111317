import { useEffect, useState } from "react";
import { useZxing } from "react-zxing";
import { Clipboard, ShoppingBasket } from "lucide-react";
import { backendUrl } from "./App";
import { useNavigate } from "react-router-dom";

export default function BarcodeScannerComponent() {
  const [result, setResult] = useState("");
  const [productDetails, setProductDetails] = useState(null);
  const [nextItemMessage, setNextItemMessage] = useState("");
  const { ref } = useZxing({
    onDecodeResult(result) {
      setResult(result.getText());
    },
  });
  const navigate = useNavigate();

  const handleAddToCart = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    cart.push(productDetails);
    localStorage.setItem("cart", JSON.stringify(cart));
    alert("Product added to cart!");
    // Reset states to allow for scanning next item
    setProductDetails(null);
    setResult(""); 
    setNextItemMessage("Scan the next item...");
  };

  useEffect(() => {
    const getProductDetails = async (barcode) => {
      try {
        const response = await fetch(`${backendUrl}/api/products?barCode=${barcode}`);
        const data = await response.json();
        if (data) {
          setProductDetails(data);
        }
      } catch (error) {
        alert("Product not found");
        console.error("Network error:", error);
      }
    };

    if (result) {
      console.log("Scanned barcode:", result);
      getProductDetails(result);
    }
  }, [result]);

  return (
    <div className="max-w-md mx-auto bg-gray-800 shadow-xl rounded-2xl overflow-hidden">
      <div className="relative rounded-2xl">
        <video 
          ref={ref} 
          className="w-full h-[70vh] object-cover p-1 rounded-2xl"
        />
        <div className="absolute top-4 right-4 bg-black/30 rounded-full p-2">
          <span className="text-white text-sm">Scanning...</span>
        </div>
        <button 
          onClick={() => navigate("/cart")} 
          className="bg-white text-black rounded-full p-1 ml-2 bottom-2 absolute"
        >
          <ShoppingBasket size={30} />
        </button>
      </div>

      {productDetails && (
        <div className="p-5 bg-blue-50 border-t border-blue-100">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold text-blue-800 mb-2">Product Details</h3>
              <p className="text-blue-700">
                <span className="font-semibold">Name:</span> {productDetails.name}
              </p>
              <p className="text-blue-700">
                <span className="font-semibold">Price:</span> ${productDetails.price}
              </p>
              <p className="text-blue-700">
                <span className="font-semibold">Description:</span> 
                {productDetails.description.length > 50 ? productDetails.description.slice(0, 50) + '...' : productDetails.description}
              </p>
            </div>
            <img 
              src={productDetails.image} 
              alt={productDetails.name} 
              className="w-24 h-24 object-cover rounded-md"
            />
          </div>
          <button
            onClick={handleAddToCart}
            className="w-full bg-blue-500 text-white rounded-full p-3 hover:bg-blue-600 transition-colors"
          >
            Add to Cart
          </button>
        </div>
      )}

      {!productDetails && nextItemMessage && (
        <div className="p-5 bg-blue-50 border-t border-blue-100">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg font-semibold text-blue-800 mb-2">
                {nextItemMessage}
              </h3>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
