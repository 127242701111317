import Logo from "./assets/Logo.png";
import { useNavigate } from "react-router-dom";

export default function Home () {
    const navigate = useNavigate();
    return (
        <div className="h-[100vh] flex-col w-full bg-black  bg-grid-white/[0.2] relative flex items-center justify-center">
      <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-black [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
      <p className="text-4xl sm:text-7xl font-bold relative z-20 bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-500 py-8">
        <img
          src={Logo}
          alt="Skaap Logo"
          className="w-[150px] h-[150px] inline-block"
        />
      </p>
      <p className="text-white text-2xl font-bold">Let's <b className="text-[#E33054]">SKAAP</b> IT!</p>
      <button onClick={() => navigate("/scan")} className="bg-[#E33054] text-white p-3 rounded-md mt-5 font-bold">Get Started</button>
      </div>
    )
}